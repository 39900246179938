import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL

export default {

  createPayment: function(data, token) {
    return axios({
      method: 'post',
      url: BASE_URL + '/payments',
      headers: {
        'Authorization': 'Bearer ' + token
      },
      data
    });
  },

  getPaymentStatus: function(payout_batch_id, token) {
    return axios({
      method: 'get',
      url: BASE_URL + '/payments/' + payout_batch_id,
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
  },

  getPayments: function(token) {
    return axios({
      method: 'get',
      url: BASE_URL + '/payments',
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
  }
};