// src/components/Profile.js

import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "../auth/react-auth0-wrapper";
import API from "../utils/api";
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

function Payments() {

  const { loading, user, token } = useAuth0();

  const [payments, setPayments] = useState([]);
  const [address, setAddress] = useState();
  const [amount, setAmount] = useState();
  const [addressType, setAddressType] = useState('EMAIL');
  const [processing, setProcessing] = useState(false);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(false);

  const columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      hide: 'sm',
    },
    {
      name: 'Requester',
      selector: 'requester',
      sortable: true,
      wrap: true,
      hide: 'sm',
    },
    {
      name: 'Recipient',
      selector: 'recipient',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'transactionstatus',
      sortable: true,
    },
    {
      name: 'Date',
      selector: 'createdat',
      sortable: true,
    },
  ]

  // if (loading || !user) {
  //   return "Loading...";
  // }

  useEffect(() => {
    getPayments()
  }, []);

  const getPayments = async () => {
    console.log("getPayments");
    let res = await API.getPayments(token);
    setPayments(res.data.transactions);
    console.log(res.data.transactions);
  }

  const createPayment = async () => {
    console.log("createPayment");
    setError(false);
    setCount(0);
    setProcessing(true);
    let res = await API.createPayment({ 
      requester: user.email,
      recipient: {
        address,
        addressType,
        amount,
        paymentCategory: 'TEEUP',
      },
    }, token);
    await schedulePaymentStatus(res.data.payout_batch_id);
  }

  const schedulePaymentStatus = async (payoutBatchId) => {
    console.log("schedulePaymentStatus");
    setCount(count + 1);
    let res = await API.getPaymentStatus(payoutBatchId, token);
    let status = res.data.payout.batch_header.batch_status;
    if ((status === 'PENDING' || status === 'PROCESSING') && count < 10) {
      setTimeout(() => {
        schedulePaymentStatus(payoutBatchId);
      }, 2000);
    } else if (res.data.payout.batch_header.batch_status === 'PENDING' && count >= 10) {
      setError(true);
    } else {
      setProcessing(false);
      setCount(0);
      await getPayments();
    }
  }

  const handleInputChange = event => {
    const { name, value } = event.target;
    console.log(name, value);
    if (name === 'address') setAddress(value);
    if (name === 'addressType') setAddressType(value);
    if (name === 'amount') setAmount(value);
  }

  const showForm = () => {
    return (
      <form>
        <div className="form-group">
          <label htmlFor="addressInput">Address</label>
          <input type="text" name="address" value={address} onChange={handleInputChange} className="form-control" id="addressInput" aria-describedby="addressHelp" placeholder="Enter email address or phone number"></input>
          <small id="addressHelp" className="form-text text-muted">This email address or phone number has to belong to a registered Coo-e user.</small>
        </div>
        <div className="form-group">
          <label htmlFor="addressTypeInput">Address Type</label>
          <select className="form-control" name="addressType" value={addressType} onChange={handleInputChange} id="addressTypeInput">
            <option value="EMAIL">Email</option>
            <option value="PHONE">Phone</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="amountInput">Amount</label>
          <input type="text" name="amount" value={amount} onChange={handleInputChange} className="form-control" id="amountInput" aria-describedby="amountHelp" placeholder="Enter amount in USD"></input>
        </div>
        <button className="btn btn-primary" disabled={!address || !amount} onClick={createPayment}>Submit</button>
      </form>
    );
  }

  const showProcessing = () => {
    let msg = 'Processing payment';;
    if (error) {
      msg = 'Error processing payment';
    }
    return (
      <div className="card" style={{ width: '18rem' }}>
        <div className="card-body">
          <h5 className="card-title">{msg}</h5>
          {error && <button type="button" className="btn btn-danger" onClick={() => {
            setError(false);
            setCount(0);
            setProcessing(false);
            setAddress('');
            setAddressType('EMAIL');
            setAmount('');
          }}>Cancel</button>}
        </div>
      </div>
    );
  }
  
  return (
    <>
      <div className="row mt-5">
        <div className="col">
          <h3>Create a payment</h3>
          {!processing && showForm()}
          {processing && showProcessing()}
        </div>
      </div>
      <div className="row mt-5">
        <div className="col">
          <h3>Payment History</h3>
          <DataTable
            columns={columns}
            data={payments}
            pagination={true}
            defaultSortField={'createdat'}
            defaultSortAsc={false}
          />
        </div>
      </div>
    </>
  );
  
};

export default Payments;