// src/components/NavBar.js

import React from "react";
import { useAuth0 } from "../auth/react-auth0-wrapper";
import { Link, NavLink } from "react-router-dom";

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout, loading } = useAuth0();

  if (loading) {
    return "Loading...";
  }

  return (

    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="#">Coo-e Admin</a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            {!isAuthenticated && (<a className="nav-link" href="#" onClick={() => loginWithRedirect({})}>Log in</a>)}
          </li>
          <li className="nav-item">
            {isAuthenticated && (<a className="nav-link" href="#" onClick={() => logout({})}>Log out</a>)}
          </li>
          <li className="nav-item">
            {isAuthenticated && (<NavLink className="nav-link" activeClassName="active" exact to="/">Home</NavLink>)}
          </li>
          <li className="nav-item">
            {isAuthenticated && (<NavLink className="nav-link" activeClassName="active" to="/payments">Payments</NavLink>)}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;