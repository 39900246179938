// src/components/Profile.js

import React from "react";
import queryString  from "query-string";

const Home = (props) => {
  let parsed = queryString.parse(props.location.search);

  return (
    <>
      {parsed.error && 
        <p>{parsed.error}</p>
      }
    </>
  );
};

export default Home;